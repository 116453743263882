exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"colors.module.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"highlight": "#29bee7",
	"green": "#05d27d",
	"lightGreen": "#5cd6a9",
	"accent": "#248eff",
	"yellow": "#f5d358",
	"lightPurple": "#c1c5d6",
	"grey": "#e7e9ef",
	"orange": "#ccb862",
	"red": "#ff7c96",
	"divider": "rgba(247, 247, 243, 0.1)",
	"textMuted": "rgba(247, 247, 243, 0.3)",
	"textBright": "rgba(247, 247, 243, 0.88)",
	"textDisabled": "rgba(247, 247, 243, 0.15)",
	"backgroundControl": "rgba(247, 247, 243, 0.1)"
};